/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_mobile_analytics_app_id": "b492f3fdad95455f949589a2d6404900",
    "aws_mobile_analytics_app_region": "us-west-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "b492f3fdad95455f949589a2d6404900",
            "region": "us-west-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://jmyl7g36brfa7n64atxpdb4f4y.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "ap-northeast-1:b1a9a2d7-66ab-44f0-bda1-536ed4770f89",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_03Ug49voS",
    "aws_user_pools_web_client_id": "4vptsurklcelu7oc489jscfgp9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "iver-resource-pro05946-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
